package com.lightningkite.mppexampleapp

import com.lightningkite.rock.navigation.*
import com.lightningkite.mppexampleapp.ArgumentsExampleScreen
import com.lightningkite.mppexampleapp.CanvasSampleScreen
import com.lightningkite.mppexampleapp.ControlsScreen
import com.lightningkite.mppexampleapp.DataLoadingExampleScreen
import com.lightningkite.mppexampleapp.DialogSamplesScreen
import com.lightningkite.mppexampleapp.DialogSampleScreen1
import com.lightningkite.mppexampleapp.ExternalServicesScreen
import com.lightningkite.mppexampleapp.FormsScreen
import com.lightningkite.mppexampleapp.FullExampleScreen
import com.lightningkite.mppexampleapp.LayoutExamplesScreen
import com.lightningkite.mppexampleapp.LoadAnimationTestScreen
import com.lightningkite.mppexampleapp.NavigationTestScreen
import com.lightningkite.mppexampleapp.PlatformSpecificScreen
import com.lightningkite.mppexampleapp.PongSampleScreen
import com.lightningkite.mppexampleapp.ReactivityScreen
import com.lightningkite.mppexampleapp.RecyclerViewScreen
import com.lightningkite.mppexampleapp.RootScreen
import com.lightningkite.mppexampleapp.SampleLogInScreen
import com.lightningkite.mppexampleapp.TestScreen
import com.lightningkite.mppexampleapp.ThemesScreen
import com.lightningkite.mppexampleapp.TwoPaneTestScreen
import com.lightningkite.mppexampleapp.VectorsTestScreen
import com.lightningkite.mppexampleapp.WebSocketScreen
import com.lightningkite.mppexampleapp.docs.DataScreen
import com.lightningkite.mppexampleapp.docs.DocSearchScreen
import com.lightningkite.mppexampleapp.docs.NavigationScreen
import com.lightningkite.mppexampleapp.docs.VideoElementScreen
import com.lightningkite.mppexampleapp.docs.ViewPagerElementScreen
import com.lightningkite.mppexampleapp.FourOhFour


val AutoRoutes = Routes(
    parsers = listOf(
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "arguments-example") return@label null
            ArgumentsExampleScreen(
                id = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
                UrlProperties.decodeFromStringMap<kotlin.collections.List<kotlin.String>>("list", it.parameters)?.let { this.list.value = it }
                UrlProperties.decodeFromStringMap<kotlin.String>("toAdd", it.parameters)?.let { this.toAdd.value = it }
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "sample") return@label null
            if (it.segments[1] != "canvas") return@label null
            CanvasSampleScreen
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "controls") return@label null
            ControlsScreen
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "sample") return@label null
            if (it.segments[1] != "data") return@label null
            DataLoadingExampleScreen
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "sample") return@label null
            if (it.segments[1] != "dialog") return@label null
            DialogSamplesScreen
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 3) return@label null
            if (it.segments[0] != "sample") return@label null
            if (it.segments[1] != "dialog") return@label null
            if (it.segments[2] != "1") return@label null
            DialogSampleScreen1
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "external-services") return@label null
            ExternalServicesScreen
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "forms") return@label null
            FormsScreen
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "full-screen") return@label null
            FullExampleScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "layout-examples") return@label null
            LayoutExamplesScreen
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "load-animation-test") return@label null
            LoadAnimationTestScreen
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "navigation") return@label null
            NavigationTestScreen
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "platform-specific") return@label null
            PlatformSpecificScreen
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "sample") return@label null
            if (it.segments[1] != "pong") return@label null
            PongSampleScreen
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "reactivity") return@label null
            ReactivityScreen
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "recycler-view") return@label null
            RecyclerViewScreen
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 0) return@label null
            RootScreen
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "sample") return@label null
            if (it.segments[1] != "login") return@label null
            SampleLogInScreen
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "test") return@label null
            TestScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "themes") return@label null
            ThemesScreen
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "twopane") return@label null
            TwoPaneTestScreen
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "test") return@label null
            if (it.segments[1] != "vectors") return@label null
            VectorsTestScreen
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "sample") return@label null
            if (it.segments[1] != "websockets") return@label null
            WebSocketScreen
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "docs") return@label null
            if (it.segments[1] != "data") return@label null
            DataScreen
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "docs") return@label null
            DocSearchScreen
            .apply {
                UrlProperties.decodeFromStringMap<kotlin.String>("query", it.parameters)?.let { this.query.value = it }
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "docs") return@label null
            if (it.segments[1] != "navigation") return@label null
            NavigationScreen
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "docs") return@label null
            if (it.segments[1] != "video") return@label null
            VideoElementScreen
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "docs") return@label null
            if (it.segments[1] != "viewpager") return@label null
            ViewPagerElementScreen
            .apply {
            }
        },
    ),
    renderers = mapOf(
        ArgumentsExampleScreen::class to label@{
            if (it !is ArgumentsExampleScreen) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.list.value, "list", p)
            UrlProperties.encodeToStringMap(it.toAdd.value, "toAdd", p)
            RouteRendered(UrlLikePath(
                segments = listOf("arguments-example", UrlProperties.encodeToString(it.id)),
                parameters = p
            ), listOf(it.list, it.toAdd))
        },
        CanvasSampleScreen::class to label@{
            if (it !is CanvasSampleScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("sample", "canvas"),
                parameters = p
            ), listOf())
        },
        ControlsScreen::class to label@{
            if (it !is ControlsScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("controls"),
                parameters = p
            ), listOf())
        },
        DataLoadingExampleScreen::class to label@{
            if (it !is DataLoadingExampleScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("sample", "data"),
                parameters = p
            ), listOf())
        },
        DialogSamplesScreen::class to label@{
            if (it !is DialogSamplesScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("sample", "dialog"),
                parameters = p
            ), listOf())
        },
        DialogSampleScreen1::class to label@{
            if (it !is DialogSampleScreen1) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("sample", "dialog", "1"),
                parameters = p
            ), listOf())
        },
        ExternalServicesScreen::class to label@{
            if (it !is ExternalServicesScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("external-services"),
                parameters = p
            ), listOf())
        },
        FormsScreen::class to label@{
            if (it !is FormsScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("forms"),
                parameters = p
            ), listOf())
        },
        FullExampleScreen::class to label@{
            if (it !is FullExampleScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("full-screen"),
                parameters = p
            ), listOf())
        },
        LayoutExamplesScreen::class to label@{
            if (it !is LayoutExamplesScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("layout-examples"),
                parameters = p
            ), listOf())
        },
        LoadAnimationTestScreen::class to label@{
            if (it !is LoadAnimationTestScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("load-animation-test"),
                parameters = p
            ), listOf())
        },
        NavigationTestScreen::class to label@{
            if (it !is NavigationTestScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("navigation"),
                parameters = p
            ), listOf())
        },
        PlatformSpecificScreen::class to label@{
            if (it !is PlatformSpecificScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("platform-specific"),
                parameters = p
            ), listOf())
        },
        PongSampleScreen::class to label@{
            if (it !is PongSampleScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("sample", "pong"),
                parameters = p
            ), listOf())
        },
        ReactivityScreen::class to label@{
            if (it !is ReactivityScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("reactivity"),
                parameters = p
            ), listOf())
        },
        RecyclerViewScreen::class to label@{
            if (it !is RecyclerViewScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("recycler-view"),
                parameters = p
            ), listOf())
        },
        RootScreen::class to label@{
            if (it !is RootScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf(),
                parameters = p
            ), listOf())
        },
        SampleLogInScreen::class to label@{
            if (it !is SampleLogInScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("sample", "login"),
                parameters = p
            ), listOf())
        },
        TestScreen::class to label@{
            if (it !is TestScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("test"),
                parameters = p
            ), listOf())
        },
        ThemesScreen::class to label@{
            if (it !is ThemesScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("themes"),
                parameters = p
            ), listOf())
        },
        TwoPaneTestScreen::class to label@{
            if (it !is TwoPaneTestScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("twopane"),
                parameters = p
            ), listOf())
        },
        VectorsTestScreen::class to label@{
            if (it !is VectorsTestScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("test", "vectors"),
                parameters = p
            ), listOf())
        },
        WebSocketScreen::class to label@{
            if (it !is WebSocketScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("sample", "websockets"),
                parameters = p
            ), listOf())
        },
        DataScreen::class to label@{
            if (it !is DataScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("docs", "data"),
                parameters = p
            ), listOf())
        },
        DocSearchScreen::class to label@{
            if (it !is DocSearchScreen) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.query.value, "query", p)
            RouteRendered(UrlLikePath(
                segments = listOf("docs"),
                parameters = p
            ), listOf(it.query))
        },
        NavigationScreen::class to label@{
            if (it !is NavigationScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("docs", "navigation"),
                parameters = p
            ), listOf())
        },
        VideoElementScreen::class to label@{
            if (it !is VideoElementScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("docs", "video"),
                parameters = p
            ), listOf())
        },
        ViewPagerElementScreen::class to label@{
            if (it !is ViewPagerElementScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("docs", "viewpager"),
                parameters = p
            ), listOf())
        },
    ),
    fallback = FourOhFour()
)
